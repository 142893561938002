import React from 'react';
import "../../assets/style/common/footer.scss";
import metamask from "../../assets/images/metamask.svg"
import { Image } from "react-bootstrap";
import { sepolia } from '@wagmi/core/chains'

const Footer = () => {
  const addMetalL2 = () => {
    if (window.ethereum) {
      return window.alert("Please log in with metamask first")
    }
    window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: process.env.REACT_APP_L2_CHAIN_ID_WITH_HEX,
        rpcUrls: [process.env.REACT_APP_L2_RPC_URL],
        chainName: process.env.REACT_APP_L2_NETWORK_NAME,
        nativeCurrency: {
          name: "ETHEREUM",
          symbol: "ETH",
          decimals: 18
        },
        blockExplorerUrls: [process.env.REACT_APP_L2_EXPLORER_URL]
      }]
    })
  }

  const addSepolia = () => {
    if (window.ethereum) {
      return window.alert("Please log in with metamask first")
    }
    window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: '0x' + sepolia.id.toString(16),
        rpcUrls: sepolia.rpcUrls,
        chainName: sepolia.name,
        nativeCurrency: sepolia.nativeCurrency,
        blockExplorerUrls: sepolia.blockExplorerUrls
      }]
    })
  }

  return (
    <>
      <footer className='app_footer'>
        {/* <Container fluid> */}
        <div className='deposit_wrap'>
          <div className='deposit_btn_wrap'>
            <button onClick={() => addMetalL2()} className='btn deposit_btn'>
                  <Image src={metamask} alt="metamask icn" height={25} width={25} fluid /> &nbsp;
                  Add Metal L2 Testnet to Wallet
            </button>
          </div>
        </div>

        <div className='deposit_wrap'>
          <div className='deposit_btn_wrap'>
            <button onClick={() => addSepolia()} className='btn deposit_btn'>
            <Image src={metamask} alt="metamask icn" height={25} width={25} fluid /> &nbsp;
                Add Sepolia Testnet to Wallet
            </button>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer